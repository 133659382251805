<template>
  <v-sheet class="listing-filters-bar d-flex align-center" rounded>
    <v-sheet
      class="mr-1 d-flex align-center"
      :width="isMobileOrIPad ? '50%' : '30%'"
    >
      <v-autocomplete
        v-if="settings.filter_by_region"
        :items="settings.regions"
        :value="region"
        outlined
        class="text-caption autocomplete-border"
        placeholder="Select region"
        prepend-inner-icon="mdi-map-marker"
        item-text="name"
        item-value="id"
        dense
        solo
        flat
        hide-details
        @change="updateFilter('region', $event)"
      />
      <v-autocomplete
        v-else
        :items="settings.cities"
        :value="city"
        outlined
        class="text-caption"
        placeholder="Select City "
        prepend-inner-icon="mdi-map-marker"
        dense
        solo
        flat
        hide-details
        @change="updateFilter('city', $event)"
      />
    </v-sheet>
    <v-icon class="d-md-none" size="3 ">fas fa-circle</v-icon>
    <site-date-picker
      @change="updateFilter('dateRange', $event)"
      :value="dateRange"
      class="d-flex text-center text-md-left px-3 align-center"
      prepend-inner-icon="mdi-calendar"
      is-custom
      is-listings-filters
      is-range
    >
      <div v-if="dateRange.startDate">
        <span class="text-caption">
          {{ parseDate(dateRange.startDate, "MMM D") }} -
          {{ parseDate(dateRange.endDate, "MMM D") }}
        </span>
      </div>
      <div v-else class="text-caption">Select date</div>
    </site-date-picker>
    <v-menu
      @input="toggleGuestsMenu"
      offset-y
      nudge-bottom="6"
      :close-on-content-click="false"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="d-none d-md-flex flex justify-center justify-md-start text-caption count-button"
          depressed
        >
          <span class="text-capitalize">
            {{ adults }} guests, {{ children }} children
          </span>
        </v-btn>
      </template>
      <v-card min-width="290">
        <v-card-text>
          <div class="d-flex font-weight-semibold mb-2">
            <span>Adults</span>
            <count-picker :value.sync="adults" />
          </div>
          <div class="d-flex font-weight-semibold">
            <span>Children</span>
            <count-picker :value.sync="children" />
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
  </v-sheet>
</template>

<script>
import { mapState, mapActions } from "vuex";
import FormattersMixin from "@/mixins/formatters-mixin";
import StylesMixin from "@/mixins/styles-mixin";
import SiteDatePicker from "@/components/common/site-date-picker";
import CountPicker from "@/components/common/count-picker.vue";

export default {
  name: "listing-filters-bar",
  components: { SiteDatePicker, CountPicker },
  mixins: [FormattersMixin, StylesMixin],
  data: () => ({
    city: null,
    region: null,
    adults: 2,
    children: 0,
    dateRange: {
      startDate: null,
      endDate: null,
    },
  }),
  computed: {
    ...mapState({
      settings: (state) => state.settingsModule.settings,
    }),
  },
  methods: {
    ...mapActions("listingsModule", ["getListings"]),

    populateFilters() {
      this.city = this.$route.query.city;
      this.region = this.$route.query.region;
      this.adults = Number(this.$route.query.adults) || 2;
      this.children = Number(this.$route.query.children) || 0;
      this.dateRange = {
        startDate: this.$route.query.check_in,
        endDate: this.$route.query.check_out,
      };
    },
    updateFilter(type, count) {
      this[type] = count;
      this.save();
    },
    toggleGuestsMenu(val) {
      const { adults, children } = this.$route.query;
      if (!val && (this.adults !== +adults || this.children !== +children))
        this.save();
    },
    save() {
      const { startDate, endDate } = this.dateRange;
      const check_in =
        startDate && this.$moment(startDate).format("YYYY-MM-DD");
      const check_out = endDate && this.$moment(endDate).format("YYYY-MM-DD");
      this.$router.push({
        name: "listings",
        query: {
          check_in,
          check_out,
          city: this.city,
          region: this.region,
          adults: this.adults,
          children: this.children,
        },
      });
    },
  },
  watch: {
    "$route.query"() {
      this.populateFilters();
    },
  },
  mounted() {
    this.populateFilters();
  },
};
</script>

<style lang="scss">
.listing-filters-bar {
  .v-input__control {
    min-height: auto !important;
  }
}
.count-button {
  height: 40px !important;
  border: 1px solid rgba(0, 0, 0, 0.38) !important;
  border-radius: 4px !important;
  background-color: white !important;
}
.autocomplete-border {
  outline: 0px;
}

.v-select__selections input::placeholder,
.v-text-field input::placeholder {
  color: black !important;
}
</style>
