<template>
  <div class="d-flex align-center ml-auto">
    <v-btn
      small
      icon
      :disabled="value <= min"
      @click="$emit('update:value', value - 1)"
    >
      <v-icon small>fa fa-minus</v-icon>
    </v-btn>
    <span class="mx-2">{{ value }}</span>
    <v-btn
      :disabled="value >= max"
      small
      icon
      @click="$emit('update:value', value + 1)"
    >
      <v-icon small>fa fa-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "count-picker",
  props: {
    value: {
      type: Number,
    },
    max: {
      type: Number,
      default: 50,
    },
    min: {
      type: Number,
      default: 1,
    },
  },
};
</script>
