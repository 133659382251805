<template>
  <div
    :class="[
      'date-picker',
      'align-center',
      { 'is-footer': showFooter },
      { 'listing-filter-container ms-2 me-3': isListingsFilters },
    ]"
  >
    <date-range-picker
      ref="picker"
      class="flex w-full"
      auto-apply
      :ranges="false"
      :date-range="value"
      :single-date-picker="!isRange"
      :min-date="minCalendarDay"
      :max-date="max"
      @select="save"
    >
      <template #input>
        <div class="d-flex justify-space-between">
          <v-icon
            v-if="isListingsFilters && !isMobile"
            size="18"
            class="mr-2 grey--text text--darken-2"
            >mdi-calendar</v-icon
          >
          <div v-if="isCustom" class="flex">
            <slot></slot>
          </div>
          <div v-else>
            <div v-if="value.startDate">
              <span v-if="isRange">
                {{ parseDate(value.startDate, "D MMMM") }} -
                {{ parseDate(value.endDate, "D MMMM") }}
              </span>
              <span v-else>
                {{ parseDate(value.startDate) }}
              </span>
            </div>
            <div v-else>Select Dates...</div>
          </div>
        </div>
      </template>
      <template #date="data">
        <div
          @click="blockSelect($event, data.date)"
          :class="[
            'data-cell',
            { rounded: rounded },
            { 'lighten-4 success-text': daysRates },
            dataStatus(data),
          ]"
        >
          <div>
            {{ data.date.getDate() }}
          </div>
        </div>
      </template>
      <template #footer v-if="isMobile && showFooter">
        <footer class="date-picker-footer">Prices and calculations</footer>
      </template>
    </date-range-picker>
    <v-btn
      v-if="value.startDate && clearable"
      class="ml-2"
      icon
      small
      @click="clear"
    >
      <v-icon small> fa fa-times </v-icon>
    </v-btn>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DeviceMixin from "@/mixins/device-mixin";
import FormattersMixin from "@/mixins/formatters-mixin";
import StylesMixin from "@/mixins/styles-mixin";
import { mapState } from "vuex";
import Vue from "vue";

// Create custom date range picker component
const CustomDateRangePicker = Vue.extend({
  extends: DateRangePicker,
  methods: {
    selectMonthDate() {
      const dt = this.end || new Date();
      this.changeLeftMonth({
        year: dt.getFullYear(),
        month: dt.getMonth() + 1,
      });
    },
    hoverDate(value) {
      if (this.readonly) return;

      if (this.in_selection) {
        const pickA = this.in_selection;
        const pickB = value;

        this.start = this.normalizeDatetime(
          Math.min(pickA.valueOf(), pickB.valueOf()),
          this.start
        );
        this.end = this.normalizeDatetime(
          Math.max(pickA.valueOf(), pickB.valueOf()),
          this.end
        );
      }

      this.$emit("hover-date", value);
    },
  },
});

export default {
  name: "site-date-picker",
  components: {
    DateRangePicker: CustomDateRangePicker,
  },
  mixins: [DeviceMixin, FormattersMixin, StylesMixin],
  props: {
    isRange: {
      type: Boolean,
      default: false,
    },
    max: {
      default: null,
    },
    value: [Object, String],
    rounded: {
      type: Boolean,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
    isCustom: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    daysRates: {
      type: [],
      default: null,
    },
    isMultiUnit: {
      type: Boolean,
      default: false,
    },
    isListingsFilters: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    const { check_in, check_out } = this.$route.query;
    if (this.daysRates && check_in && check_out)
      this.save({
        startDate: check_in,
        endDate: check_out,
      });
  },
  computed: {
    ...mapState({
      settings: (state) => state.settingsModule.settings,
    }),
    minCalendarDay() {
      return this.$moment()
        .add(this.settings.min_ahead || 0, "days")
        .format("YYYY-MM-DD");
    },
  },
  methods: {
    save(data = { startDate: null, endDate: null }) {
      if (this.$moment(data.startDate).isSame(data.endDate, "day")) return;
      if (!this.daysRates) {
        this.$emit("update:value", data);
        this.$emit("change", data);
        return;
      }
      const dates = this.getDatesList(
        this.$moment(data.startDate),
        this.$moment(data.endDate)
      );
      const statuses = [];
      const minNights = [];

      dates.forEach((d) => {
        const parsedDate = this.parseDate(d, "YYYY-MM-DD");
        statuses.push(this.daysRates[parsedDate].status);
        minNights.push(this.daysRates[parsedDate].minNights);
      });

      const nights = dates.length;
      if (nights === 0) {
        return this.$emit("update:error-text", "Please select a date range.");
      }

      if (minNights.some((n) => n > nights)) {
        return this.$emit(
          "update:error-text",
          "Minimum stay is " + Math.max(...minNights) + " nights."
        );
      }

      if (statuses.some((s) => s !== "available")) {
        return this.$emit(
          "update:error-text",
          "Some dates are not available, please select again."
        );
      }
      this.$emit("update:error-text", null);
      this.$emit("update:value", data);
      this.$emit("change", data);
    },
    clear() {
      this.save();
    },
    getDatesList(startDate, endDate) {
      const dates = [];
      while (startDate < endDate) {
        dates.push(startDate.toDate());
        startDate = startDate.clone().add(1, "days");
      }
      return dates;
    },
    blockSelect(e, date) {
      if (this.isBlockedDate(date) && !this.isCheckoutOnly(date))
        e.stopPropagation();
    },

    isBlockedDate(date) {
      const { daysRates } = this;
      const day = this.parseDate(date, "YYYY-MM-DD");
      if (!daysRates) return false;
      const dayRate = daysRates[day];
      const noUnits =
        dayRate && this.isMultiUnit && dayRate.available_units === 0;
      return !dayRate || noUnits || dayRate.status !== "available";
    },
    isCheckoutOnly(date) {
      const { daysRates } = this;
      const prev = this.$moment(date).subtract(1, "days").format("YYYY-MM-DD");
      return daysRates[prev] && daysRates[prev].status === "available";
    },
    dataStatus({ date }) {
      const isBlocked = this.isBlockedDate(date);
      if (!isBlocked) return "available";
      return this.isCheckoutOnly(date) ? "checkoutOnly" : "blocked";
    },
  },
};
</script>

<style lang="scss">
.listing-filter-container {
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
  padding: 9px 0;
}
.vue-daterange-picker {
  .off {
    visibility: hidden;
  }
  .blocked {
    text-decoration: line-through;
    font-weight: 400;
    color: map-get($grey, "base");
  }
  .available {
    color: black;
    font-weight: 600;
  }
  .checkoutOnly {
    font-weight: 600;
    color: map-get($grey, "darken-1");
  }
  .daterangepicker {
    margin-top: 16px;
    border: 0;
    box-shadow: 0 10px 20px #00000019;
    &::before,
    &::after {
      display: none;
    }
    .calendars {
      background-color: #fff;
      height: auto;
    }
    .calendar-table {
      th,
      td {
        width: auto;
        height: auto;
        font-size: 13px;
        &.in-range {
          background-color: map-get($cyan, "lighten-5");
        }
        &.active {
          .data-cell {
            background-color: map-get($cyan, "darken-4");
            border-radius: 40px;
            &.rounded {
              border-radius: 4px;
            }
          }
          .available,
          .checkoutOnly {
            color: white;
          }
        }
        &.disabled {
          background-color: #fff;
        }
        &.start-date {
          border-top-left-radius: 40px;
          border-bottom-left-radius: 40px;
        }
        &.end-date {
          border-top-right-radius: 40px;
          border-bottom-right-radius: 40px;
        }
      }
      th {
        height: 40px;
      }
      .data-cell {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        font-family: $body-font-family;
        line-height: 1.2;
        &-price {
          font-size: 11px;
        }
      }
    }
    .drp {
      &-calendar {
        width: auto;
        max-width: none;
      }
    }
  }
  .reportrange-text {
    border: 0;
    padding: 0;
  }
}
@media #{map-get($display-breakpoints, 'sm-only')} {
  .show-calendar {
    position: fixed;
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .date-picker {
    &.is-footer {
      .calendars {
        margin-bottom: 72px;
      }
    }
  }
  .vue-daterange-picker {
    .daterangepicker {
      position: fixed;
      margin-top: 0;
      top: 0 !important;
      left: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      transform: translate(0) !important;
      width: 100%;
      height: 100vh;
      overflow-y: auto;
      &.show-calendar {
        display: flex;
      }
      .calendars {
        height: 100vh;
      }
      .calendar-table {
        th,
        td {
          width: 40px;
          height: 40px;
          padding: 0;
        }
        .data-cell {
          width: 100%;
        }
      }
    }
    .date-picker-footer {
      position: fixed;
      display: flex;
      align-items: center;
      background-color: #fff;
      padding: 16px 10px;
      height: 72px;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: 0 -5px 10px #00000019;
    }
  }
}
</style>
