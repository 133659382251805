<template>
  <v-app>
    <v-main class="pa-0">
      <router-view />
      <GlobalSnack />
    </v-main>
  </v-app>
</template>

<script>
import GlobalSnack from "@/components/common/global-snack.vue";
import { mapState } from "vuex";

export default {
  name: "App",
  components: { GlobalSnack },
  data: () => ({}),
  computed: {
    ...mapState({
      settings: (state) => state.settingsModule.settings,
      listing: (state) => state.listingsModule.currentListing,
    }),
  },
  watch: {
    listing(val) {
      this.setMetaTags(val);
    },
    settings: {
      handler() {
        this.setMetaTags({
          nickname: this.settings.sections.header.title,
          title: this.settings.sections.header.sub_title,
          picture: this.settings.bg_image,
        });

        // Initialize Google Analytics
        const tag = this.settings.analytics.google;
        if (tag) {
          const tagManagerScript = document.createElement("script");
          const url = `https://www.googletagmanager.com/gtag/js?id=${tag}`;
          tagManagerScript.setAttribute("src", url);
          document.head.appendChild(tagManagerScript);

          window.dataLayer = window.dataLayer || [];
          window.gtag = function () {
            window.dataLayer.push(arguments);
          };
          window.gtag("js", new Date());
          window.gtag("config", tag);
        }

        // Initialize Facebook Pixel
        const pixelId = this.settings.analytics.pixel;
        if (pixelId) {
          this.initializeFacebookPixel(pixelId);
        }

        // Inject custom scripts
        const script = document.createElement("script");
        script.innerHTML = this.settings.injected_scripts;
        document.head.appendChild(script);
      },
    },
  },
  methods: {
    setMetaTags({ nickname, title, picture }) {
      const image = picture.replace(
        "upload/",
        "upload/w_600,h_336,c_fill,q_auto,f_auto/"
      );
      this.updateMetaTag("property", "og:title", nickname);
      this.updateMetaTag("property", "og:description", title);
      this.updateMetaTag("property", "og:image", image);
      this.updateMetaTag("property", "og:image:alt", "property image");
      this.updateMetaTag("property", "og:url", window.location.href);
      this.updateMetaTag("property", "og:type", "website");

      // Add Facebook Pixel meta tag if configured
      const pixelId = this.settings?.analytics?.pixel;
      if (pixelId) {
        this.updateMetaTag("facebook-domain-verification", "", pixelId);
      }
    },
    updateMetaTag(attrName, attrValue, content) {
      let element = document.querySelector(`meta[${attrName}="${attrValue}"]`);
      if (!element) {
        element = document.createElement("meta");
        element.setAttribute(attrName, attrValue);
        document.head.appendChild(element);
      }
      element.setAttribute("content", content);
    },
    initializeFacebookPixel(pixelId) {
      /* eslint-disable */
      (function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      window.fbq("init", pixelId);
      window.fbq("track", "PageView");
      /* eslint-enable */
    },
  },
};
</script>
<style lang="sass">
@import '~vuetify/src/styles/styles.sass'

@each $position in relative, absolute, fixed
  .p-#{$position}
    position: $position

.font-weight-semibold
  font-weight: 600 !important

.theme--light.v-divider
  border-color: map-get($grey, "lighten-3") !important
</style>
