<template>
  <div v-if="settings.bg_image" class="home">
    <site-header :dark="isIntersecting" :inverted="isMobile" flat />
    <img v-if="isMobile" :src="settings.bg_image" width="100%" />
    <v-parallax
      v-else
      dark
      class="overflow-visible"
      :src="bgImage"
      :height="isMobile ? screenHeight * 0.6 : screenHeight"
    >
      <v-card
        v-if="!isMobile"
        color="transparent"
        elevation="0"
        dark
        class="d-flex flex-column"
        style="height: 50vh"
      >
        <hello-world
          v-if="header"
          class="mb-6"
          :title="header.title"
          :subtitle="header.sub_title"
        />
        <home-filters v-intersect="onIntersect" />
      </v-card>
    </v-parallax>
    <v-card v-if="isMobile" class="mt-n2 mb-lg-10" elevation="0">
      <hello-world
        v-if="header"
        is-mobile
        class="mt-6"
        :title="header.title"
        :subtitle="header.sub_title"
      />
      <home-filters v-intersect="onIntersect" />
    </v-card>
    <v-container>
      <site-listings
        :title="HOME_LISTINGS_TITLE"
        class="mb-8 mb-sm-16"
        lg="4"
        featured
      />
    </v-container>
    <home-top-destinations
      v-if="!!settings.destination_cities.length"
      :cities="settings.destination_cities"
    />
    <!-- <home-features /> -->
    <home-reviews />
    <!-- <home-trigger>
      <home-filters />
    </home-trigger> -->
    <site-footer />
    <!-- <site-bottom-navigation v-if="isMobile" /> -->
  </div>
</template>

<script>
import {
  BACKDROP,
  HOME_TITLE,
  HOME_SUBTITLE,
  HOME_LISTINGS_TITLE,
} from "@/consts/CMS";
import { mapActions, mapState } from "vuex";
import DeviceMixin from "@/mixins/device-mixin";
import MediaMixin from "@/mixins/media-mixin";
import SiteHeader from "@/components/header/site-header";
import HelloWorld from "@/components/home/hello-world";
import HomeFilters from "@/components/home/home-filters.vue";
import SiteListings from "@/components/common/site-listings.vue";
import HomeTopDestinations from "@/components/home/home-top-destinations";
// import HomeFeatures from "@/components/home/home-features";
import HomeReviews from "@/components/home/home-reviews.vue";
// import HomeTrigger from "@/components/home/home-trigger";
import SiteFooter from "@/components/footer/site-footer";
// import SiteBottomNavigation from "@/components/footer/site-bottom-navigation";

export default {
  name: "home-view",
  components: {
    SiteHeader,
    HelloWorld,
    HomeFilters,
    SiteListings,
    HomeTopDestinations,
    SiteFooter,
    HomeReviews,
    // HomeFeatures,
  },
  mixins: [DeviceMixin, MediaMixin],
  data: () => ({
    BACKDROP,
    HOME_TITLE,
    HOME_SUBTITLE,
    HOME_LISTINGS_TITLE,
    height: 0,
    isIntersecting: true,
    bottomMenuItem: "search",
  }),
  computed: {
    screenHeight() {
      return window.screen.height;
    },
    header() {
      return this.settings?.sections?.header;
    },
    ...mapState({
      settings: (state) => state.settingsModule.settings,
    }),
    bgImage() {
      return this.isMobile
        ? this.settings?.bg_image?.replace("upload/", "upload/c_fill,ar_1:2/")
        : this.resize(this.settings?.bg_image, this.screenHeight);
    },
  },
  methods: {
    ...mapActions("usersModule", ["getUsers"]),
    onIntersect(entries) {
      this.isIntersecting = entries[0].isIntersecting;
    },
  },
  mounted() {
    window.scrollTo({ top: 0 });
    this.getUsers();
  },
};
</script>

<style>
.v-parallax__image-container {
  filter: brightness(0.8);
}
</style>
