<template>
  <section class="home-listings grey lighten-3 py-8 py-sm-16">
    <v-container>
      <h2 class="text-center text-h5 font-weight-medium mb-7 mb-md-16">
        {{ HOME_TOP_DESTINATIONS_TITLE }}
      </h2>
      <v-row class="justify-center mx-0 mx-md-16">
        <v-col v-for="item in filteredCities" :key="item.id" cols="6" md="4">
          <home-top-destinations-item :item="item" />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import {
  HOME_TOP_DESTINATIONS_TITLE,
  HOME_TOP_DESTINATIONS_BUTTON_TEXT,
} from "@/consts/CMS";
import HomeTopDestinationsItem from "@/components/home/home-top-destinations-item.vue";

export default {
  name: "home-top-destinations",
  components: { HomeTopDestinationsItem },
  props: {
    cities: {
      type: Array,
    },
  },
  data: () => ({
    HOME_TOP_DESTINATIONS_TITLE,
    HOME_TOP_DESTINATIONS_BUTTON_TEXT,
  }),
  computed: {
    filteredCities() {
      return this.cities.filter((city) => city.image);
    },
  },
};
</script>

<style scoped></style>
