<template>
  <div class="carousel">
    <v-carousel
      :value="slide"
      height
      :interval="interval"
      :cycle="cycle"
      hide-delimiters
      show-arrows
      @change="$emit('update:slide', $event)"
    >
      <v-carousel-item v-for="item in items" :key="item.id" :eager="loadAll">
        <site-carousel-item
          class="rounded-lg"
          :item="item"
          :easy="easy"
          :easy-contain="easyContain"
          :tile="tile"
          :listing-id="listingId"
          :item-height="itemHeight"
          eager
        />
      </v-carousel-item>
    </v-carousel>
    <div
      v-if="showDots"
      :class="[
        'carousel-dots',
        { 'carousel-dots-easy': easy },
        'd-flex',
        'justify-center',
      ]"
    >
      <button
        v-for="d in items.length"
        :key="`dot-${d - 1}`"
        :class="[
          {
            active: slide === d - 1,
          },
          'carousel-dots-item',
        ]"
        @click="$emit('update:slide', d - 1)"
      />
    </div>
  </div>
</template>

<script>
import SiteCarouselItem from "@/components/common/carousel/site-carousel-item.vue";
export default {
  name: "site-carousel",
  components: { SiteCarouselItem },
  props: {
    easy: {
      type: Boolean,
      default: false,
    },
    easyContain: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
    },
    slide: {
      type: Number,
      default: 0,
    },
    showDots: {
      type: Boolean,
      default: false,
    },
    interval: {
      type: Number,
      default: 6000,
    },
    cycle: {
      type: Boolean,
      default: false,
    },
    tile: {
      type: Boolean,
      default: false,
    },
    listingId: {
      type: [Number, String],
      default: null,
    },
    itemHeight: {
      type: String,
      default: null,
    },
    loadAll: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.loadNextImage(this.slide);
  },
  watch: {
    slide(val) {
      this.loadNextImage(val);
    },
  },
  methods: {
    loadNextImage(val) {
      const nextIndex = (val + 1) % this.items.length;
      const nextImage = new Image();
      nextImage.src = this.items[nextIndex];
    },
  },
};
</script>

<style lang="scss">
.carousel {
  position: relative;
  &-dots {
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    &-easy {
      position: absolute;
      padding-top: 15px;
      padding-bottom: 25px;
      bottom: 0;
      .carousel-dots-item {
        background-color: #fff;
      }
    }
    &-item {
      display: block;
      background-color: #000;
      margin-left: 4px;
      margin-right: 4px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      opacity: 0.3;
      &.active {
        opacity: 1;
      }
    }
  }
}
</style>
